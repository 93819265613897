import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { CreateUniversityProps } from '../../../Models/University';
import { createUnivertyAPIRequest } from './createUniversityAPI';

interface CounterState {
  isCreating: boolean;
  createStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  createResponse: CreateUniversityProps;
  selectAllCountries: boolean;
  selectAllLanguages: boolean;
}

const initialState: CounterState = {
  isCreating: false,
  createStatus: 'IDLE',
  createResponse: {
    name: '',
    shortName: '',
    branding: {
      logoUrl: '',
      favIconUrl: '',
      primaryColor: '',
      secondaryColor: '',
      tagLine: '',
      defaultCoverPhotoUrl: '',
      defaultProfilePhotoUrl: '',
    },
    programmes: [
      {
        name: '',
        courses: [],
      },
    ],
    types: [],
    programDisplayName: '',
    courseDisplayName: '',
    mobileVerification: true,
    countrySearchEnabled: true,
    adminEmailAddress: '',
    googleAnalyticsConfig: {
      key: '',
    },
    zapierConfig: {
      authUrl: '',
      key: '',
    },
    wordpressPluginEnabled: true,
    timeZone: '',
    leadPerMonth: '100',
    maxBuddyCount: 15,
    ambMobileApp: true,
    adminMobileApp: true,
    technicalSupport: true,
    monthlyUsageReport: true,
    monthlyAmbLeaderboard: true,
    versionUpgrade: true,
    languageTranslation: true,
    leadEmailValidation: true,
    contentModeration: true,
    ambAffiliateMarketingReferral: true,
    multiRoleSelection: true,
    multiCountrySelection: true,
    multiCampusSelection: true,
    aiConversationReport: true,
    ambProspectRatingsComments: true,
    transferToAdmin: true,
    customerEngagementPlan: true,
    followingUpAmb: true,
    notifyForProspects: true,
    notifyForAmb: true,
    enableFeed: true,
    coPilotAssistance: true,
    gamifatication: true,
    emailVerification: true,
    buddyVideoEnable: true,
    enableReferral: true,
    paidSubscription: false,
    countryCodes: [],
    interestList: [],
    clientLanguages: [],
    customInterests: [],
    universityLocations: [],
    locationList: [],
    activeMultiLocations: false,
  },
  selectAllCountries: false,
  selectAllLanguages: false,
};

export const createRequest = createAsyncThunk('create/createInfo', async (university: CreateUniversityProps) => {
  return await createUnivertyAPIRequest(university);
});

const createUniversitySlice = createSlice({
  name: 'create',
  initialState: initialState,
  reducers: {
    toggleSelectAllCountries: (state, action: PayloadAction<boolean>) => {
      state.selectAllCountries = action.payload;
    },
    toggleSelectAllLanguages: (state, action: PayloadAction<boolean>) => {
      state.selectAllLanguages = action.payload;
    },
    clearCreateResponse: (state) => {
      state.isCreating = false;
      state.createStatus = 'IDLE';
      state.createResponse = {
        name: '',
        shortName: '',
        branding: {
          logoUrl: '',
          favIconUrl: '',
          primaryColor: '',
          secondaryColor: '',
          tagLine: '',
          defaultCoverPhotoUrl: '',
          defaultProfilePhotoUrl: '',
        },
        programmes: [
          {
            name: '',
            courses: [],
          },
        ],
        types: [],
        programDisplayName: '',
        courseDisplayName: '',
        mobileVerification: true,
        countrySearchEnabled: true,
        adminEmailAddress: '',
        googleAnalyticsConfig: {
          key: '',
        },
        zapierConfig: {
          authUrl: '',
          key: '',
        },
        wordpressPluginEnabled: true,
        timeZone: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRequest.pending, (state) => {
        state.createStatus = 'PENDING';
        state.isCreating = true;
      })
      .addCase(createRequest.fulfilled, (state, action) => {
        console.log('Creating request fulfilled', action.payload);
        // Add user to the state array
        state.createStatus = 'SUCCESS';
        state.isCreating = false;
        if (action.payload) {
          state.createResponse = action.payload;
        }
      })
      .addCase(createRequest.rejected, (state) => {
        state.createStatus = 'FAILED';
        state.isCreating = false;
      });
  },
});

export const createUniversitySelector = (state: RootState) => state.createUniversity;

export const { clearCreateResponse, toggleSelectAllCountries, toggleSelectAllLanguages } =
  createUniversitySlice.actions;

export default createUniversitySlice.reducer;
