import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { createAdminAPIRequest, getAdminListAPIRequest, deleteAdminAPIRequest } from './AdministrationAPI';

export interface CounterState {
  isInformationFetching: boolean;
  isCreateFetching: boolean;
  createStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  informationFetchingStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  deleteStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  isDeleteFetching: boolean;
  response: Array<{ id: number }>;
  createResponse: {};
}

export interface CreateStatusProps {
  id?: number;
  firstName?: string;
  email?: string;
  username?: string;
  password?: string;
  status?: string;
  locationId?: number;
  superAdmin?: boolean;
  eligibleForChat?: boolean;
}

const initialState: CounterState = {
  isInformationFetching: false,
  informationFetchingStatus: 'IDLE',
  response: [],
  isCreateFetching: false,
  createStatus: 'IDLE',
  createResponse: {},
  deleteStatus: 'IDLE',
  isDeleteFetching: false,
};

export const getAdminListRequest = createAsyncThunk('admin/getAdmin', async (id: number) => {
  return await getAdminListAPIRequest(id);
});

export const createAdminRequest = createAsyncThunk('admin/createAdmin', async (data: CreateStatusProps) => {
  return await createAdminAPIRequest(data);
});

export const deleteAdminRequest = createAsyncThunk('admin/deleteAdmin', async (id: number) => {
  return await deleteAdminAPIRequest(id);
});

const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState,
  reducers: {
    clearAdmin: (state) => {
      state.isInformationFetching = false;
      state.informationFetchingStatus = 'IDLE';
      state.response = [];
    },
  },
  extraReducers: (builder) => {
    // Get Admin
    builder
      .addCase(getAdminListRequest.pending, (state) => {
        state.informationFetchingStatus = 'PENDING';
        state.isInformationFetching = true;
      })
      .addCase(getAdminListRequest.fulfilled, (state, action: any) => {
        state.informationFetchingStatus = 'SUCCESS';
        state.isInformationFetching = false;
        state.response = action.payload;
      })
      .addCase(getAdminListRequest.rejected, (state) => {
        state.informationFetchingStatus = 'FAILED';
        state.isInformationFetching = false;
      })

      // Create Admin
      .addCase(createAdminRequest.pending, (state) => {
        state.createStatus = 'PENDING';
        state.isCreateFetching = true;
      })
      .addCase(createAdminRequest.fulfilled, (state, action: any) => {
        state.createStatus = 'SUCCESS';
        state.isCreateFetching = false;
        state.createResponse = action.payload;
      })
      .addCase(createAdminRequest.rejected, (state) => {
        state.createStatus = 'FAILED';
        state.isCreateFetching = false;
      })

      // Delete Admin
      .addCase(deleteAdminRequest.pending, (state) => {
        state.isDeleteFetching = true;
        state.deleteStatus = 'PENDING';
      })
      .addCase(deleteAdminRequest.fulfilled, (state, action: any) => {
        state.deleteStatus = 'SUCCESS';
        state.isDeleteFetching = false;
        state.response = state.response.filter((admin) => admin.id !== action.payload.id);
      })
      .addCase(deleteAdminRequest.rejected, (state) => {
        state.deleteStatus = 'FAILED';
        state.isDeleteFetching = false;
      });
  },
});

export const adminSelector = (state: RootState) => state.admin;

export const { clearAdmin } = adminSlice.actions;

export default adminSlice.reducer;
