import { authenticatedRequest } from '../../../../utils/axios/commonAxios';

interface ValidatorProps {
  id?: number;
  firstName?: string;
  email?: string;
  username?: string;
  password?: string;
  status?: string;
  locationId?: number;
  eligibleForChat?: boolean;
  superAdmin?: boolean;
}

export const createAdminAPIRequest = async (data: ValidatorProps) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${data?.id}/admin`,
    'post',
    {
      data: {
        firstName: data?.firstName,
        username: data?.username,
        email: data?.email,
        password: data?.password,
        status: data?.status,
        locationId: data?.locationId,
        superAdmin: data?.superAdmin,
        eligibleForChat: data?.eligibleForChat,
      },
    }
  );
};

export const getAdminListAPIRequest = async (id: number) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${id}/admins`,
    'get'
  );
};

export const deleteAdminAPIRequest = async (id: number) => {
  return await authenticatedRequest(`${process.env.REACT_APP_BACKEND_HOST}/support/api/admin/${id}`, 'delete');
};
